import React from "react"
import Select from "react-select"
import extend from "lodash/extend"
import { Input } from "reactstrap"

import { LabelBox } from "../common/forms/labelBox"

export enum POS {
  Adjective = "adjective",
  Verb = "verb",
  Noun = "noun",
}

export interface Grammar {
  pos?: POS
  plural?: string
}

interface Props {
  grammar?: Grammar
  setGrammar: (grammar: Grammar) => void
}

export default function ConceptGrammar(props: Props) {
  const { grammar } = props

  const handleSubmit = (grammar: Grammar | null, key: any, value: any) =>
    props.setGrammar(extend({}, grammar, Object.fromEntries(new Map([[key, value]]))))

  const posOptions = [POS.Adjective, POS.Noun, POS.Verb].map((pos) => ({
    value: pos,
    label: pos,
  }))

  return (
    <div>
      <LabelBox label="Part of speech">
        <Select
          className="flex-grow"
          // @ts-ignore
          onChange={(option) => handleSubmit(grammar, "pos", option.value)}
          options={posOptions}
          value={posOptions.find((o) => o.value === grammar?.pos) || null}
        />
      </LabelBox>

      <LabelBox label="Plural">
        <Input onChange={(e: any) => handleSubmit(grammar || {}, "plural", e.target.value)} value={grammar?.plural || ""} />
      </LabelBox>
    </div>
  )
}
