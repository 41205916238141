import React, { useEffect, useState } from "react"
import Select from "react-select"
import Slider from "rc-slider"
import isNumber from "lodash/isNumber"
import isUndefined from "lodash/isUndefined"
import sortBy from "lodash/sortBy"
import without from "lodash/without"
import { Button } from "reactstrap"
import { navigate } from "gatsby"
import { useDispatch, useSelector } from "react-redux"

import {
  ConceptState,
  conceptSelector,
  deleteConceptImageAppearanceAction,
  deleteRootAppearanceAction,
  fetchConceptAction,
  fetchRootsAction,
  insertRootAppearanceAction,
  updateConceptAction,
} from "../hasura/slices/concept"

import ConceptGrammar, { Grammar } from "../components/concept/grammar"
import Layout from "../components/layout"
import { CurriculumState, curriculumSelector } from "../hasura/slices/curriculum"
import { EDIT_CONCEPT } from "../lib/pedagogeEventTypeIds"
import { NavigableProgress } from "../components/common/progress"
import { QueryName } from "../hasura/queryNames"
import { imageFor, queryParams } from "../lib/helpers"
import { insertPedagogueEventAction, updateAdminConceptsQueueAction, userSelector, UserState } from "../hasura/slices/user"
import { root_appearances_insert_input } from "../../types/globalTypes"
import { setGalleryEntityAction } from "../hasura/slices/image"

// @ts-ignore
import deleteIcon from "../lib/images/delete.svg"

export default function ConceptComponent() {
  const dispatch = useDispatch()

  const { concept, roots, isQuerying }: ConceptState = useSelector(conceptSelector)
  const { accessToken, user }: UserState = useSelector(userSelector)
  const { curriculum }: CurriculumState = useSelector(curriculumSelector)

  const [conceptDefinitionIndexes, setConceptDefinitionIndexes] = useState<number[]>([])
  const [conceptRootIndexes, setConceptRootIndexes] = useState<number[]>([])
  const [editedDefinition, setEditedDefinition] = useState<string>("")
  const [values, setValues] = useState<string[] | undefined>([])
  const [valueIdx, setValueIdx] = useState<number | undefined>()
  const [isEditingDefinition, setIsEditingDefinition] = useState<boolean>(false)
  const [isHoveringConceptIdx, setIsHoveringConceptIdx] = useState<number | undefined>()
  const [isHoveringDefinitionIdx, setIsHoveringDefinitionIdx] = useState<number | undefined>()
  const [rootId, setRootId] = useState<number | undefined>()
  const [grammar, setGrammar] = useState<Grammar | undefined>()

  const isSelectingRootIndexes = conceptRootIndexes.length < 2
  const isSelectingDefinitionIndexes = conceptRootIndexes.length === 2
  const isSelectingStartIndex = conceptRootIndexes.length === 0 || (isSelectingDefinitionIndexes && conceptDefinitionIndexes.length === 0)
  const isDoneSelectingIndexes = conceptDefinitionIndexes.length === 2
  const isInQueue = (values || []).length > 1

  /*
    Effects
  */

  useEffect(() => {
    const { values } = queryParams()

    if (values) {
      setValues(values.split(","))
      setValueIdx(0)
    }
  }, [])

  useEffect(() => {
    if (!accessToken) return

    dispatch(fetchRootsAction(accessToken!))
  }, [accessToken])

  useEffect(() => {
    if (!values?.length || !isNumber(valueIdx) || !accessToken || !curriculum) return

    fetchConcept()
  }, [valueIdx, values, accessToken, curriculum])

  useEffect(() => {
    setIsEditingDefinition(false)
    setGrammar(concept?.grammar)
    setEditedDefinition("")
    if (concept?.definition) setEditedDefinition(concept.definition)
  }, [concept])

  useEffect(() => {
    if (!isDoneSelectingIndexes) return

    handleSaveRootAppearance()
  }, [isDoneSelectingIndexes])

  /*
    Methods
  */

  const fetchConcept = () => dispatch(fetchConceptAction(accessToken!, values![valueIdx!], curriculum?.id))

  const updateConcept = () =>
    dispatch(updateConceptAction(accessToken!, concept!.id, concept!.obscurity, concept!.definition, grammar || null, false))

  const markComplete = () => {
    updateConcept()
    const concepts = without(user?.concepts_queue_denormalized?.split(","), concept?.display_name)
      .filter((s) => s)
      .join(",")
    dispatch(insertPedagogueEventAction(accessToken!, { concept_id: concept?.id, user_id: user!.id, pedagogue_event_type_id: EDIT_CONCEPT }))
    dispatch(updateAdminConceptsQueueAction(accessToken!, user!.id, concepts))
    handleNext()
  }

  const handleNext = () => {
    updateConcept()

    if (values![valueIdx! + 1]) {
      setValueIdx(valueIdx! + 1)
    } else {
      navigate("/home")
    }
  }

  const handleBack = () => {
    updateConcept()
    setValueIdx(valueIdx! - 1)
  }

  const deleteRootAppearance = (id: number) => dispatch(deleteRootAppearanceAction(accessToken!, id, concept!.display_name))

  const handleClickedConceptIdx = (idx: number) => setConceptRootIndexes(conceptRootIndexes.concat(idx).sort())

  const handleClickedDefinitionIdx = (idx: number) => setConceptDefinitionIndexes(conceptDefinitionIndexes.concat(idx).sort())

  const handleClickedSave = async () => {
    if (isEditingDefinition) {
      await dispatch(updateConceptAction(accessToken!, concept!.id, concept?.obscurity || null, editedDefinition || null, grammar || null))
      setIsEditingDefinition(false)
    } else {
      setIsEditingDefinition(true)
    }
  }

  const handleSaveRootAppearance = () => {
    const [definition_start_index, definition_end_index] = conceptDefinitionIndexes.sort((a, b) => a - b)
    const [rootStartIndex, rootEndIndex] = conceptRootIndexes.sort((a, b) => a - b)
    const rootAppearance: root_appearances_insert_input = {
      concept_id: concept?.id,
      root_id: rootId,
      value: concept?.display_name.slice(rootStartIndex, rootEndIndex + 1),
      start_index: conceptRootIndexes[0],
      definition_start_index,
      definition_end_index,
    }
    dispatch(insertRootAppearanceAction(accessToken!, rootAppearance, concept!.display_name))
    setRootId(undefined)
    setConceptDefinitionIndexes([])
    setConceptRootIndexes([])
  }

  const handleUpdateDifficulty = (difficulty: number) =>
    dispatch(updateConceptAction(accessToken!, concept!.id, difficulty, concept?.definition || null, grammar || null))

  const handleDeleteConceptImageAppearance = async (id: number) => {
    if (!window.confirm("Are you sure you want to delete this image?")) return

    await dispatch(deleteConceptImageAppearanceAction(accessToken!, id))
    fetchConcept()
  }

  return (
    <Layout>
      {concept ? (
        <div style={{ paddingBottom: "150px" }} className="max-width-900px mx-auto">
          <div className="my-3">
            <div className="d-flex align-items-start justify-content-between mb-3">
              <p className="text-xxxl mb-2">
                {concept.display_name.split("").map((c: string, idx: number) => {
                  const disabled = conceptRootIndexes.length === 2 || !rootId

                  return (
                    <span
                      className={`
                        ${rootId && isHoveringConceptIdx === idx ? "underline" : ""} 
                        ${rootId && isSelectingRootIndexes ? "text--danger pointer" : ""} 
                        ${disabled ? "pe-none" : "hover-text"}
                      `}
                      key={idx}
                      onClick={() => handleClickedConceptIdx(idx)}
                      onMouseEnter={() => setIsHoveringConceptIdx(idx)}
                      onMouseLeave={() => setIsHoveringConceptIdx(undefined)}
                    >
                      {c}
                    </span>
                  )
                })}
              </p>

              {isInQueue && !isUndefined(valueIdx) && !isUndefined(values) && (
                <div className="d-flex flex-column align-items-end flex-grow ml-3">
                  <NavigableProgress
                    idx={valueIdx}
                    count={values.length}
                    isLoading={isQuerying[QueryName.FetchConcept]}
                    handleSave={markComplete}
                    handleBack={handleBack}
                    handleSkip={handleNext}
                    backDisabled={valueIdx === 0 || isQuerying[QueryName.FetchConcept]}
                    nextDisabled={isQuerying[QueryName.FetchConcept]}
                  />

                  <div className="d-flex justify-content-end mt-3">
                    {user?.concepts_queue_denormalized?.split(",").includes(concept.display_name) ? (
                      <Button onClick={markComplete} color="primary">
                        Mark complete
                      </Button>
                    ) : null}
                  </div>
                </div>
              )}
            </div>

            <div style={{ height: "1px", minHeight: "60px", maxWidth: "600px" }}>
              {isEditingDefinition ? (
                <textarea
                  className="p-2 w-100 h-100 rounded text-m"
                  onChange={(e) => setEditedDefinition(e.target.value)}
                  placeholder="Definition..."
                  value={editedDefinition || ""}
                  autoFocus
                />
              ) : (
                <div className="border rounded h-100">
                  <p className="p-2">
                    {concept.definition
                      ? concept.definition?.split("").map((c: string, idx: number) => {
                          const disabled = conceptRootIndexes.length < 2 || !rootId || conceptDefinitionIndexes.length === 2
                          return (
                            <span
                              className={`
                                text-m
                                ${rootId && !isSelectingRootIndexes ? "text--danger pointer" : ""} 
                                ${isHoveringDefinitionIdx === idx ? "underline" : ""} 
                                ${disabled ? "pe-none" : "hover-text"}
                               `}
                              key={idx}
                              onClick={() => handleClickedDefinitionIdx(idx)}
                              onMouseEnter={() => setIsHoveringDefinitionIdx(idx)}
                              onMouseLeave={() => setIsHoveringDefinitionIdx(undefined)}
                            >
                              {c}
                            </span>
                          )
                        })
                      : "No Definition"}
                  </p>
                </div>
              )}
            </div>

            <Button onClick={handleClickedSave} className="mt-2 d-block" color={isEditingDefinition ? "success" : "secondary"}>
              {isEditingDefinition ? "Save" : "Edit"}
            </Button>
          </div>

          {!curriculum?.display_name.includes("therapy") && (
            <div className="mb-2">
              <p className="subheader">Roots</p>

              <div className="my-2">
                {sortBy(concept.root_appearances, "start_index").map((r, idx) => (
                  <div className="d-flex align-items-center my-1" key={idx}>
                    <p className="m-0 mr-2">
                      {r.value.toUpperCase()} - {concept.definition?.slice(r.definition_start_index, r.definition_end_index + 1)}
                    </p>

                    <img onClick={() => deleteRootAppearance(r.id)} className="svg-icon hover" src={deleteIcon} />
                  </div>
                ))}
              </div>

              <Select
                className="max-width-400px"
                onChange={(o: any) => setRootId(o.value)}
                placeholder="Add root..."
                options={roots.map((r) => ({
                  value: r.id,
                  label: `${r.display_name}  - ${r.definition}`,
                }))}
              />

              {rootId && (
                <p className="mt-2 text--danger font-italic">
                  Select {isSelectingStartIndex ? "start" : "end"} root index in {isSelectingRootIndexes ? "concept" : "definition"}.
                </p>
              )}
            </div>
          )}

          <div>
            <p className="subheader">Grammar</p>

            <ConceptGrammar grammar={grammar} setGrammar={setGrammar} />
          </div>

          <div>
            <p className="subheader">Difficulty</p>

            <Slider
              // @ts-ignore
              onChange={(difficulty) => handleUpdateDifficulty(difficulty)}
              className="mt-3"
              defaultValue={concept.obscurity || 1}
              min={1}
              max={10}
              marks={{ 1: 1, 2: 2, 3: 3, 4: 4, 5: 5, 6: 6, 7: 7, 8: 8, 9: 9, 10: 10 }}
            />
          </div>

          <div>
            <p className="subheader">Images</p>

            <div style={{ gap: "30px" }} className="d-flex flex-wrap">
              {concept.concept_image_appearances.map((c, idx) => (
                <div key={idx} className="position-relative pointer">
                  <img
                    className="height-200px width-auto border"
                    key={idx}
                    onClick={() => window.open(`/image?id=${c.image.id}`, "_blank")}
                    src={imageFor(c.image.s3_path)}
                  />
                  <img
                    onClick={() => handleDeleteConceptImageAppearance(c.id)}
                    className="icon-m m-1 border border-danger rounded bg--white position-absolute r-0 b-0 p-2"
                    src={deleteIcon}
                  />
                  <div style={{ bottom: "-20px" }} className="position-absolute r-0 text-xxs text--gray8 single-line">
                    {c.image.quality || "n/a"}Q{c.image.caption ? ", caption" : ""}
                  </div>
                </div>
              ))}
            </div>

            <Button className="mt-4" color="success" onClick={() => dispatch(setGalleryEntityAction(concept))}>
              Search images
            </Button>
          </div>
        </div>
      ) : null}
    </Layout>
  )
}
