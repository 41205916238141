import React from "react"
import { Button, Progress } from "reactstrap"

// @ts-ignore
import arrowRightIcon from "../../../lib/images/arrow-right.svg"
// @ts-ignore
import arrowLeftIcon from "../../../lib/images/arrow-left.svg"

interface NavigableProgressProps {
  count: number
  idx: number
  handleBack: () => void
  handleSkip: () => void
  handleSave: () => void
  backDisabled: boolean
  nextDisabled: boolean
  isLoading: boolean
}

export const NavigableProgress = (props: NavigableProgressProps) => {
  const { idx, count } = props

  return (
    <div
      style={{ bottom: "20px" }}
      className="bg--white l-0 r-0 mx-auto max-width-450px p-4 position-fixed d-flex flex-column shadow border rounded z-index-10000000"
    >
      <div className="d-flex align-items-center">
        <h5 className="bold m-0 mr-5">Progress</h5>

        <Progress style={{ height: "10px" }} className="flex-grow" value={(100 * (idx + 1)) / count} />

        <p className="my-0 ml-2 text-right text-xs">
          {idx + 1} of {count}
        </p>
      </div>

      <div className="w-100 d-flex justify-content-end mt-3">
        <div className="flex-center">
          <p className="text-s text--gray8 m-0 mr-2">Back</p>

          <img
            onClick={() => props.handleBack()}
            src={arrowLeftIcon}
            className={`${props.backDisabled || props.isLoading ? "pe-none opacity-50" : ""} pointer icon-m mr-3`}
          />

          <img
            onClick={() => props.handleSkip()}
            src={arrowRightIcon}
            className={`${props.nextDisabled || props.isLoading ? "pe-none opacity-50" : ""} pointer icon-m ml-3`}
          />

          <p className="text-s text--gray8 m-0 ml-2">Skip</p>
        </div>

        <Button disabled={props.isLoading} onClick={() => props.handleSave()} className="min-width-100px flex-center ml-5" color="primary">
          Save
        </Button>
      </div>
    </div>
  )
}
