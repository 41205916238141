import React from "react"

interface LabelBoxProps {
  children: any
  label: string
  error?: string
}

export const LabelBox = (props: LabelBoxProps) => {
  return (
    <div className="max-width-400px my-2">
      <div className="d-flex align-items-center">
        <p style={{ minWidth: "100px" }} className="text-s bold m-0 mr-3">
          {props.label}
        </p>

        {props.children}
      </div>

      {props.error && <p className="m-0 text-danger mt-1 text-xs text-right">{props.error}</p>}
    </div>
  )
}
